.days {
  margin: 3% auto 0;
  padding: 0 3%;
  position: relative;
  z-index: 10;

  @include pc {
    max-width: 1024px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  @include sp {
    width: 90%;
    margin: 0 auto;
  }
}

.days__list {
  width: 48%;
  margin: 60px auto 0;

  @include pc {
    max-width: 484px;
  }

  @include sp {
    width: 100%;
  }
}

.days__ttl {
  font-family: $font_futura;
  padding-bottom: 20px;
  @include fz(40);
  line-height: 1.175;
  text-align: center;
  color: $red;

  @include sp {
    padding-bottom: 15px;
    @include fz(32);
  }
}

.days__thumb {
  width: 100%;
  height: 100%;
  display: block;

  > img {
    border: 1px solid #fff;
  }

  @include pc {
    transition: opacity .3s ease;
  }

  &:hover {
    :last-child {
      @include pc {
        opacity: .8;
      }
    }
  }
}

.days__link {
  $this: &;
  margin-top: 35px;
  transition: opacity .3s ease;

  &_ttl {
    display: block;
    margin-bottom: 12px;
    color: $black;
  }

  &--yt {
    @extend #{$this};
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    position: relative;
    background-color: $black;
    text-align: center;
    color: $white;
    font-weight: normal;
    @include fz(16);

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      background-size: contain;
      background-repeat: no-repeat;
    }

    &::before {
      left: 20px;
      width: 35px;
      height: 24px;
      background-image: url(../img/index/icon_youtube.svg);
    }

    &::after {
      right: 20px;
      width: 11px;
      height: 11px;
      background-image: url(../img/index/icon_outerlink.svg);
    }
    
    span {
      text-align: left;
      color: $white;
      line-height: 1.2;
    }
  }

  &--ytWhite {
    @extend #{$this}--yt;
    background-color: $red;

    &::before {
      background-image: url(../img/index/icon_youtube_white.svg);
    }
  }

  &--outline {
    width: auto;
    display: block;
    position: relative;
    border: 1px solid #000;
    @include fz(14);
    padding: 10px;
    line-height: 1.5;
    color: #000;

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      right: 10px;
      width: 5px;
      height: 5px;
      border-right: 2px solid #000;
      border-bottom: 2px solid #000;
      transform: rotate(-45deg) translateY(-50%);
    }
  }
}
