.footer {
  padding: 2% 0;
  background-color: $white;
  position: relative;

  @include sp {
    padding: 30px 0;
  }
}

.footer__copylight {
  font-family: $font_noto;
  @include fz(14);
  text-align: center;
  line-height: 1;
}

.footer__btn {
  font-family: $font_noto;
  bottom: 44px;
  // bottom: 80px;
  right: 8.6%;
  writing-mode: vertical-rl;
  @include fz(10);
  font-family: $font_noto;
  overflow: hidden;
  box-sizing: border-box;
  position: fixed;
  // position: absolute;
  z-index: 9900;
  height: 66px;

  @include pc {
    display: none;
  }

  @include sp {
    height: 72px;
    display: block !important;
    opacity: 0;
    right: 7.2%;
    bottom: 82% !important;
    transition: opacity .3s ease, bottom .6s ease;
  }
  
  &.is-spTop {
    @include sp {
      opacity: 1;
      position: fixed !important;
      bottom: 82% !important;
      z-index: 9900;

      &::after {
        animation: scrollBtnDown 1.3s linear 0s infinite;
      }
    }
  }

  &.is-spScrolling {
    @include sp {
      height: 66px;
      opacity: 1;
      position: fixed !important;
      bottom: 44px !important;
      z-index: 9900;
      transition: opacity .3s ease, bottom 0s ease;
    }
  }

  &.is-spFooter {
    @include sp {
      position: absolute !important;
      bottom: 284px !important;
      transition: opacity .3s ease, bottom 0s ease;
    }
  }

  &::after {
    content: "";
    display: block;
    width: 2px;
    height: 18px;
    background: $black;
    position: absolute;
    left: 0;
    // top: -18px;
    animation: scrollBtnTop 1.3s linear 0s infinite;
  }
  &:before {
    content: "";
    display: block;
    width: 2px;
    height: 100%;
    background: $white;
    position: absolute;
    left: 0;
  }
  a {
    display: block;
    width: 100%;
    height: 100%;
    padding-left: 16px;
    color: $white;
    text-decoration: none;
  }
}