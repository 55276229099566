#container {
  overflow: hidden;
  display: block;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -10;
  canvas {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -10;
  }
}
