.modal {
  width: 100%;
  height: 100%;
}

.modal__inner {
  width: 100%;
  height: 100%;

  iframe {
    width: 100%;
    height: 100%;
  }
}