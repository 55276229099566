body {
  // background-color: #000;
}

img {
  width: 100%;
}

a {
  text-decoration: none;
  display: block;
}