.mv {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @include sp {
    height: 74vh;
  }
}

.mv__logo {
  width: 29%;
  
  @include pc {
    padding: 8% 0;
    max-width: 425px;
  }

  @include sp {
    width: 60%;
  }
}