@import "_variables";
@import "_mixin";

//iconfont setting
%iconfont {
	font-family: 'icomoon' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	vertical-align: top;

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

// triangle
%triangle {
	width: 0;
	height: 0;
	display: inline-block;
	border-style: solid;
	position: absolute;
}
