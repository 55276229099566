.triangle {
  &--about {
    width: 100%;
    height: 100%;
    position: relative;

    &:before {
      @extend %triangle;
      content: "";
      border-width: 27.3vw 104vw 0 0;
      border-color: $black transparent transparent transparent;
      top: 0;
      left: 0;
      z-index: -1;

      @include sp {
        border-width: 36vw 140vw 0 0;
      }
    }
  }

  &--sponsor {
    width: 100%;
    height: 100%;
    padding-bottom: 20px;
    position: relative;

    &:before {
      @extend %triangle;
      content: "";
      border-width: 0 0 27.3vw 104vw;
      border-color: transparent transparent $paple transparent;
      bottom: 99.5%;
      right: 0;
      z-index: 0;

      @include sp {
        border-width: 0 0 36vw 140vw;
      }
    }
  }
}
