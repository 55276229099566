.about {
  padding: 7.5% 0 22%;
  overflow: hidden;
  background: rgba(0,0,0,0.6);
}

.about__ttl {
  margin-bottom: 5.5%;
  font-family: $font_futura;
  @include fz(70);
  line-height: 1;
  text-align: center;
  color: $red;
  position: relative;

  @include sp {
    margin-bottom: 65px;
    @include fz(50);
    line-height: 1.2;
  }

  &:after {
    content: "";
    width: 69%;
    height: 1px;
    margin: 0 auto;
    background-color: $red;
    position: absolute;
    right: 0;
    left: 0;
    bottom: -30px;

    @include pc {
      max-width: 1024px;
    }

    @include sp {
      width: 90%;
      bottom: -25px;
    }
  }

  span {
    display: inline-block;
    transform: rotate(15deg);
    margin-left: 0.2em;
  }
}

.about__box {
  width: 100%;

  @include sp {
    width: 89.5%;
    margin: 0 auto;
  }
}

.about__text {
  font-family: $font_noto;
  @include fz(16);
  line-height: 2.43;
  text-align: center;
  color: $white;

  @include sp {
    @include fz(14);
    line-height: 2;
    text-align: left;
  }

  &+& {
    margin-top: 3.5%;

    @include sp {
      margin-top: 35px;
    }
  }

  &--bold {
    @extend .about__text;
    @include fz(20);
    font-weight: bold;

    @include sp {
      @include fz(18);
      text-align: center;
    }
  }
}
