.sponsor {
  background-color: $paple;
  position: relative;
}

.sponsor__logo {
  width: 43%;
  margin: 0 auto 2%;

  @include pc {
    max-width: 289px;
  }

  @include sp {
    width: 60%;
    margin: 0 auto 0px;
  }
}

.sponsor__box {
  margin: 0 auto;
  position: relative;
  top: -9vw;

  @include pc {
    max-width: 676px;
  }

  @include sp {
    margin: 30px auto 0;
    padding-bottom: 80px;
    top: -30px;
  }
}

.sponsor__cont {
  margin-bottom: 5%;

  @include pc {
    display: flex;
    justify-content: center;
    // width: 385px;
    margin: 36px auto 0;
  }

  @include sp {
    // width: 60%;
    margin: 40px auto;
  }
}

.sponsor__list {
  &--bnr {
    // @include pc {
    //   width: 260px;
    // }
    @include pc {
      & + .sponsor__list--bnr {
        margin-left: 20px;
      }
    }
    &:nth-of-type(1){
      width: 236px;
      @include sp {
        margin: 0 auto;
      }
    }
    &:nth-of-type(2){
      width: 126px;
      @include sp {
        margin: 20px auto 0;
      }
    }
    &:nth-of-type(3){
      width: 360px;
      @include sp {
        width: 280px;
        margin: 20px auto 0;
      }
    }
  }

  &--text {
    @include pc {
      width: 268px;
    }

    @include sp {
      margin-top: 20px;
    }
  }
}

.sponsor__text {
  font-family: $font_noto;
  @include fz(16);
  line-height: 1;
  text-align: center;
  color: $white;

  @include sp {
    @include fz(12);
    line-height: 1.8;
  }
}

.sponsor__btm {
  width: 36%;
  margin: 0 auto;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;

  @include pc {
    max-width: 533px;
  }

  @include sp {
    width: 80%;
  }
}

.sponsor__contact {
  font-family: $font_noto;
  padding: 6% 0;
  box-sizing: border-box;
  display: block;
  @include fz(28);
  line-height: 1;
  text-decoration: none;
  text-align: center;
  color: $white;
  background-color: $black;
  border: 2px solid $black;
  position: relative;

  @include pc {
    transition: color .3s ease, background-color .3s ease;
  }

  @include sp {
    padding: 8% 0 8% 12%;
    text-align: center;
    @include fz(20);
    font-weight: bold;
  }

  &:before {
    content: $icon_mail;
    @extend %iconfont;
    @include fz(27);
    color: $white;
    position: absolute;
    top: 50%;
    left: 6%;
    transform: translateY(-50%);

    @include pc {
      transition: color .3s ease;
    }

  }

  &:hover {
    @include pc {
      color: $black;
      background-color: $white;
    }

    &:before {
      @include pc {
        color: $black;
      }
    }
  }
}

.sponsor__twitter {
  width: 56px;
  height: 56px;
  border: 1px solid $white;
  border-radius: 50%;

  @include pc {
    position: absolute;
    top: 50%;
    right: calc(-1*(56px + 14%));
    transform: translateY(-50%);
    transition: border-color .3s ease;
  }

  @include sp {
    margin: 20px auto 0;
    position: relative;
  }

  &:after {
    content: $icon_tw;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 0;
    transform: translate(-50%, -50%);
    @extend %iconfont;
    @include fz(30);
    color: $white;

    @include pc {
      transition: color .3s ease;
    }
  }

  &:hover {
    @include pc {
      border-color: $black;

      &:after {
        color: $black;
      }
    }
  }

  a {
    width: 100%;
    height: 100%;
    display: block;
    cursor: pointer;
    position: relative;
    z-index: 2;
  }
}

.sponsor__link {
  &--bnr {
    width: 100%;
    height: 100%;
    display: block;
    position: relative;

    @include pc {
      transition: opacity .3s ease;
    }

    &:hover {
      @include pc {
        opacity: .7;
      }
    }
  }

  &--text {
    width: 100%;
    height: 100%;
    display: block;
    background: url('../img/index/amerikamuranokai.svg') no-repeat center;
    background-size: contain;

    @include pc {
      transition: background-image .3s ease;
    }

    @include sp {
      height: 18vw;
    }

    &:hover {
      @include pc {
        background-image: url('../img/index/amerikamuranokai_hover.svg')
      }
    }
  }
}
