@charset "UTF-8";


/* ********************************************************
	共通初期化CSS
******************************************************** */
@import url(https://use.typekit.net/uey4htd.css);
@import url(https://fonts.googleapis.com/earlyaccess/notosansjp.css);
@import url(https://use.typekit.net/uey4htd.css);
@import url(https://fonts.googleapis.com/earlyaccess/notosansjp.css);
@import url(https://use.typekit.net/uey4htd.css);
@import url(https://fonts.googleapis.com/earlyaccess/notosansjp.css);
@import url(https://use.typekit.net/uey4htd.css);
@import url(https://fonts.googleapis.com/earlyaccess/notosansjp.css);
html, body, div, header, footer, article, section, aside, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, figure {
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: none;
}

html, body {
  font-size: 10px;
}

body {
  -webkit-text-size-adjust: 100%;
  line-height: 100%;
}

ol, ul, li {
  list-style: none;
}

img {
  -webkit-backface-visibility: hidden;
  -ms-interpolation-mode: bicubic;
  vertical-align: bottom;
}

ins {
  text-decoration: none;
}

del {
  text-decoration: line-through;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

:focus {
  outline: 0;
}

caption, th, td {
  text-align: left;
  font-weight: normal;
}

.clear, div .clear {
  width: 0;
  height: 0;
  font-size: 0;
  line-height: 0;
  text-indent: -9999px;
  clear: both;
}

/*---clearFix---*/
.clearFix {
  zoom: 1;
}

.clearFix:after {
  content: ".";
  clear: both;
  display: block;
  height: 0;
  visibility: hidden;
}

@media print {
  body {
    zoom: 60%;
  }
}

@keyframes scrollBtnTop {
  0% {
    bottom: -18px;
  }
  100% {
    bottom: calc(100% + 18px);
  }
}

@keyframes scrollBtnDown {
  0% {
    top: -18px;
  }
  100% {
    top: calc(100% + 18px);
  }
}

@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon.eot");
  src: url("../fonts/icomoon.eot") format("embedded-opentype"), url("../fonts/icomoon.ttf") format("truetype"), url("../fonts/icomoon.woff") format("woff"), url("../fonts/icomoon.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon.eot");
  src: url("../fonts/icomoon.eot") format("embedded-opentype"), url("../fonts/icomoon.ttf") format("truetype"), url("../fonts/icomoon.woff") format("woff"), url("../fonts/icomoon.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (max-width: 767px) {
  .pc {
    display: none !important;
  }
}

@media screen and (min-width: 767px) {
  .sp {
    display: none !important;
  }
}

.sponsor__contact:before, .sponsor__twitter:after {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: top;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.triangle--about:before, .triangle--sponsor:before {
  width: 0;
  height: 0;
  display: inline-block;
  border-style: solid;
  position: absolute;
}

@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon.eot");
  src: url("../fonts/icomoon.eot") format("embedded-opentype"), url("../fonts/icomoon.ttf") format("truetype"), url("../fonts/icomoon.woff") format("woff"), url("../fonts/icomoon.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon.eot");
  src: url("../fonts/icomoon.eot") format("embedded-opentype"), url("../fonts/icomoon.ttf") format("truetype"), url("../fonts/icomoon.woff") format("woff"), url("../fonts/icomoon.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (max-width: 767px) {
  .pc {
    display: none !important;
  }
}

@media screen and (min-width: 767px) {
  .sp {
    display: none !important;
  }
}

img {
  width: 100%;
}

a {
  text-decoration: none;
  display: block;
}

.footer {
  padding: 2% 0;
  background-color: #fff;
  position: relative;
}

@media screen and (max-width: 767px) {
  .footer {
    padding: 30px 0;
  }
}

.footer__copylight {
  font-family: "Noto Sans", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "游ゴシック", YuGothic, "メイリオ", Meiryo, sans-serif;
  font-size: 14px;
  font-size: 1.4rem;
  text-align: center;
  line-height: 1;
}

.footer__btn {
  font-family: "Noto Sans", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "游ゴシック", YuGothic, "メイリオ", Meiryo, sans-serif;
  bottom: 44px;
  right: 8.6%;
  writing-mode: vertical-rl;
  font-size: 10px;
  font-size: 1rem;
  font-family: "Noto Sans", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "游ゴシック", YuGothic, "メイリオ", Meiryo, sans-serif;
  overflow: hidden;
  box-sizing: border-box;
  position: fixed;
  z-index: 9900;
  height: 66px;
}

@media screen and (min-width: 767px) {
  .footer__btn {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .footer__btn {
    height: 72px;
    display: block !important;
    opacity: 0;
    right: 7.2%;
    bottom: 82% !important;
    transition: opacity .3s ease, bottom .6s ease;
  }
}

@media screen and (max-width: 767px) {
  .footer__btn.is-spTop {
    opacity: 1;
    position: fixed !important;
    bottom: 82% !important;
    z-index: 9900;
  }
  .footer__btn.is-spTop::after {
    animation: scrollBtnDown 1.3s linear 0s infinite;
  }
}

@media screen and (max-width: 767px) {
  .footer__btn.is-spScrolling {
    height: 66px;
    opacity: 1;
    position: fixed !important;
    bottom: 44px !important;
    z-index: 9900;
    transition: opacity .3s ease, bottom 0s ease;
  }
}

@media screen and (max-width: 767px) {
  .footer__btn.is-spFooter {
    position: absolute !important;
    bottom: 284px !important;
    transition: opacity .3s ease, bottom 0s ease;
  }
}

.footer__btn::after {
  content: "";
  display: block;
  width: 2px;
  height: 18px;
  background: #222;
  position: absolute;
  left: 0;
  animation: scrollBtnTop 1.3s linear 0s infinite;
}

.footer__btn:before {
  content: "";
  display: block;
  width: 2px;
  height: 100%;
  background: #fff;
  position: absolute;
  left: 0;
}

.footer__btn a {
  display: block;
  width: 100%;
  height: 100%;
  padding-left: 16px;
  color: #fff;
  text-decoration: none;
}

.mv {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .mv {
    height: 74vh;
  }
}

.mv__logo {
  width: 29%;
}

@media screen and (min-width: 767px) {
  .mv__logo {
    padding: 8% 0;
    max-width: 425px;
  }
}

@media screen and (max-width: 767px) {
  .mv__logo {
    width: 60%;
  }
}

.triangle--about {
  width: 100%;
  height: 100%;
  position: relative;
}

.triangle--about:before {
  content: "";
  border-width: 27.3vw 104vw 0 0;
  border-color: #222 transparent transparent transparent;
  top: 0;
  left: 0;
  z-index: -1;
}

@media screen and (max-width: 767px) {
  .triangle--about:before {
    border-width: 36vw 140vw 0 0;
  }
}

.triangle--sponsor {
  width: 100%;
  height: 100%;
  padding-bottom: 20px;
  position: relative;
}

.triangle--sponsor:before {
  content: "";
  border-width: 0 0 27.3vw 104vw;
  border-color: transparent transparent #b60081 transparent;
  bottom: 99.5%;
  right: 0;
  z-index: 0;
}

@media screen and (max-width: 767px) {
  .triangle--sponsor:before {
    border-width: 0 0 36vw 140vw;
  }
}

.about {
  padding: 7.5% 0 22%;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.6);
}

.about__ttl {
  margin-bottom: 5.5%;
  font-family: futura-pt, sans-serif;
  font-size: 70px;
  font-size: 7rem;
  line-height: 1;
  text-align: center;
  color: #e60012;
  position: relative;
}

@media screen and (max-width: 767px) {
  .about__ttl {
    margin-bottom: 65px;
    font-size: 50px;
    font-size: 5rem;
    line-height: 1.2;
  }
}

.about__ttl:after {
  content: "";
  width: 69%;
  height: 1px;
  margin: 0 auto;
  background-color: #e60012;
  position: absolute;
  right: 0;
  left: 0;
  bottom: -30px;
}

@media screen and (min-width: 767px) {
  .about__ttl:after {
    max-width: 1024px;
  }
}

@media screen and (max-width: 767px) {
  .about__ttl:after {
    width: 90%;
    bottom: -25px;
  }
}

.about__ttl span {
  display: inline-block;
  transform: rotate(15deg);
  margin-left: 0.2em;
}

.about__box {
  width: 100%;
}

@media screen and (max-width: 767px) {
  .about__box {
    width: 89.5%;
    margin: 0 auto;
  }
}

.about__text, .about__text--bold {
  font-family: "Noto Sans", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "游ゴシック", YuGothic, "メイリオ", Meiryo, sans-serif;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 2.43;
  text-align: center;
  color: #fff;
}

@media screen and (max-width: 767px) {
  .about__text, .about__text--bold {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 2;
    text-align: left;
  }
}

.about__text + .about__text, .about__text--bold + .about__text, .about__text + .about__text--bold, .about__text--bold + .about__text--bold {
  margin-top: 3.5%;
}

@media screen and (max-width: 767px) {
  .about__text + .about__text, .about__text--bold + .about__text, .about__text + .about__text--bold, .about__text--bold + .about__text--bold {
    margin-top: 35px;
  }
}

.about__text--bold {
  font-size: 20px;
  font-size: 2rem;
  font-weight: bold;
}

@media screen and (max-width: 767px) {
  .about__text--bold {
    font-size: 18px;
    font-size: 1.8rem;
    text-align: center;
  }
}

.days {
  margin: 3% auto 0;
  padding: 0 3%;
  position: relative;
  z-index: 10;
}

@media screen and (min-width: 767px) {
  .days {
    max-width: 1024px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 767px) {
  .days {
    width: 90%;
    margin: 0 auto;
  }
}

.days__list {
  width: 48%;
  margin: 60px auto 0;
}

@media screen and (min-width: 767px) {
  .days__list {
    max-width: 484px;
  }
}

@media screen and (max-width: 767px) {
  .days__list {
    width: 100%;
  }
}

.days__ttl {
  font-family: futura-pt, sans-serif;
  padding-bottom: 20px;
  font-size: 40px;
  font-size: 4rem;
  line-height: 1.175;
  text-align: center;
  color: #e60012;
}

@media screen and (max-width: 767px) {
  .days__ttl {
    padding-bottom: 15px;
    font-size: 32px;
    font-size: 3.2rem;
  }
}

.days__thumb {
  width: 100%;
  height: 100%;
  display: block;
}

.days__thumb > img {
  border: 1px solid #fff;
}

@media screen and (min-width: 767px) {
  .days__thumb {
    transition: opacity .3s ease;
  }
}

@media screen and (min-width: 767px) {
  .days__thumb:hover :last-child {
    opacity: .8;
  }
}

.days__link, .days__link--yt, .days__link--ytWhite {
  margin-top: 35px;
  transition: opacity .3s ease;
}

.days__link_ttl {
  display: block;
  margin-bottom: 12px;
  color: #222;
}

.days__link--yt, .days__link--ytWhite {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  position: relative;
  background-color: #222;
  text-align: center;
  color: #fff;
  font-weight: normal;
  font-size: 16px;
  font-size: 1.6rem;
}

.days__link--yt::before, .days__link--ytWhite::before, .days__link--yt::after, .days__link--ytWhite::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-size: contain;
  background-repeat: no-repeat;
}

.days__link--yt::before, .days__link--ytWhite::before {
  left: 20px;
  width: 35px;
  height: 24px;
  background-image: url(../img/index/icon_youtube.svg);
}

.days__link--yt::after, .days__link--ytWhite::after {
  right: 20px;
  width: 11px;
  height: 11px;
  background-image: url(../img/index/icon_outerlink.svg);
}

.days__link--yt span, .days__link--ytWhite span {
  text-align: left;
  color: #fff;
  line-height: 1.2;
}

.days__link--ytWhite {
  background-color: #e60012;
}

.days__link--ytWhite::before {
  background-image: url(../img/index/icon_youtube_white.svg);
}

.days__link--outline {
  width: auto;
  display: block;
  position: relative;
  border: 1px solid #000;
  font-size: 14px;
  font-size: 1.4rem;
  padding: 10px;
  line-height: 1.5;
  color: #000;
}

.days__link--outline::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 10px;
  width: 5px;
  height: 5px;
  border-right: 2px solid #000;
  border-bottom: 2px solid #000;
  transform: rotate(-45deg) translateY(-50%);
}

.sponsor {
  background-color: #b60081;
  position: relative;
}

.sponsor__logo {
  width: 43%;
  margin: 0 auto 2%;
}

@media screen and (min-width: 767px) {
  .sponsor__logo {
    max-width: 289px;
  }
}

@media screen and (max-width: 767px) {
  .sponsor__logo {
    width: 60%;
    margin: 0 auto 0px;
  }
}

.sponsor__box {
  margin: 0 auto;
  position: relative;
  top: -9vw;
}

@media screen and (min-width: 767px) {
  .sponsor__box {
    max-width: 676px;
  }
}

@media screen and (max-width: 767px) {
  .sponsor__box {
    margin: 30px auto 0;
    padding-bottom: 80px;
    top: -30px;
  }
}

.sponsor__cont {
  margin-bottom: 5%;
}

@media screen and (min-width: 767px) {
  .sponsor__cont {
    display: flex;
    justify-content: center;
    margin: 36px auto 0;
  }
}

@media screen and (max-width: 767px) {
  .sponsor__cont {
    margin: 40px auto;
  }
}

@media screen and (min-width: 767px) {
  .sponsor__list--bnr + .sponsor__list--bnr {
    margin-left: 20px;
  }
}

.sponsor__list--bnr:nth-of-type(1) {
  width: 236px;
}

@media screen and (max-width: 767px) {
  .sponsor__list--bnr:nth-of-type(1) {
    margin: 0 auto;
  }
}

.sponsor__list--bnr:nth-of-type(2) {
  width: 126px;
}

@media screen and (max-width: 767px) {
  .sponsor__list--bnr:nth-of-type(2) {
    margin: 20px auto 0;
  }
}

.sponsor__list--bnr:nth-of-type(3) {
  width: 360px;
}

@media screen and (max-width: 767px) {
  .sponsor__list--bnr:nth-of-type(3) {
    width: 280px;
    margin: 20px auto 0;
  }
}

@media screen and (min-width: 767px) {
  .sponsor__list--text {
    width: 268px;
  }
}

@media screen and (max-width: 767px) {
  .sponsor__list--text {
    margin-top: 20px;
  }
}

.sponsor__text {
  font-family: "Noto Sans", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "游ゴシック", YuGothic, "メイリオ", Meiryo, sans-serif;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1;
  text-align: center;
  color: #fff;
}

@media screen and (max-width: 767px) {
  .sponsor__text {
    font-size: 12px;
    font-size: 1.2rem;
    line-height: 1.8;
  }
}

.sponsor__btm {
  width: 36%;
  margin: 0 auto;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
}

@media screen and (min-width: 767px) {
  .sponsor__btm {
    max-width: 533px;
  }
}

@media screen and (max-width: 767px) {
  .sponsor__btm {
    width: 80%;
  }
}

.sponsor__contact {
  font-family: "Noto Sans", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "游ゴシック", YuGothic, "メイリオ", Meiryo, sans-serif;
  padding: 6% 0;
  box-sizing: border-box;
  display: block;
  font-size: 28px;
  font-size: 2.8rem;
  line-height: 1;
  text-decoration: none;
  text-align: center;
  color: #fff;
  background-color: #222;
  border: 2px solid #222;
  position: relative;
}

@media screen and (min-width: 767px) {
  .sponsor__contact {
    transition: color .3s ease, background-color .3s ease;
  }
}

@media screen and (max-width: 767px) {
  .sponsor__contact {
    padding: 8% 0 8% 12%;
    text-align: center;
    font-size: 20px;
    font-size: 2rem;
    font-weight: bold;
  }
}

.sponsor__contact:before {
  content: "";
  font-size: 27px;
  font-size: 2.7rem;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 6%;
  transform: translateY(-50%);
}

@media screen and (min-width: 767px) {
  .sponsor__contact:before {
    transition: color .3s ease;
  }
}

@media screen and (min-width: 767px) {
  .sponsor__contact:hover {
    color: #222;
    background-color: #fff;
  }
}

@media screen and (min-width: 767px) {
  .sponsor__contact:hover:before {
    color: #222;
  }
}

.sponsor__twitter {
  width: 56px;
  height: 56px;
  border: 1px solid #fff;
  border-radius: 50%;
}

@media screen and (min-width: 767px) {
  .sponsor__twitter {
    position: absolute;
    top: 50%;
    right: calc(-1*(56px + 14%));
    transform: translateY(-50%);
    transition: border-color .3s ease;
  }
}

@media screen and (max-width: 767px) {
  .sponsor__twitter {
    margin: 20px auto 0;
    position: relative;
  }
}

.sponsor__twitter:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 0;
  transform: translate(-50%, -50%);
  font-size: 30px;
  font-size: 3rem;
  color: #fff;
}

@media screen and (min-width: 767px) {
  .sponsor__twitter:after {
    transition: color .3s ease;
  }
}

@media screen and (min-width: 767px) {
  .sponsor__twitter:hover {
    border-color: #222;
  }
  .sponsor__twitter:hover:after {
    color: #222;
  }
}

.sponsor__twitter a {
  width: 100%;
  height: 100%;
  display: block;
  cursor: pointer;
  position: relative;
  z-index: 2;
}

.sponsor__link--bnr {
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
}

@media screen and (min-width: 767px) {
  .sponsor__link--bnr {
    transition: opacity .3s ease;
  }
}

@media screen and (min-width: 767px) {
  .sponsor__link--bnr:hover {
    opacity: .7;
  }
}

.sponsor__link--text {
  width: 100%;
  height: 100%;
  display: block;
  background: url("../img/index/amerikamuranokai.svg") no-repeat center;
  background-size: contain;
}

@media screen and (min-width: 767px) {
  .sponsor__link--text {
    transition: background-image .3s ease;
  }
}

@media screen and (max-width: 767px) {
  .sponsor__link--text {
    height: 18vw;
  }
}

@media screen and (min-width: 767px) {
  .sponsor__link--text:hover {
    background-image: url("../img/index/amerikamuranokai_hover.svg");
  }
}

.modal {
  width: 100%;
  height: 100%;
}

.modal__inner {
  width: 100%;
  height: 100%;
}

.modal__inner iframe {
  width: 100%;
  height: 100%;
}

#container {
  overflow: hidden;
  display: block;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -10;
}

#container canvas {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -10;
}
