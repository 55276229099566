//カラー
$black: #222;
$white: #fff;
$gray: #6e6e6e;
$red : #e60012;
$yellow: #f08300;
$blue: #00a0e9;
$green: #6dbb2c;
$paple: #b60081;

//フォント指定
@import url(https://use.typekit.net/uey4htd.css);
@import url(https://fonts.googleapis.com/earlyaccess/notosansjp.css);

$font_yumin: 游明朝,"Yu Mincho",YuMincho,"Hiragino Mincho ProN",HGS明朝E,メイリオ,Meiryo,serif;
$font_yugo: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
$font_yugo_bold: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
$font_noto: 'Noto Sans', "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "游ゴシック", YuGothic, "メイリオ", Meiryo, sans-serif;
$font_futura: futura-pt, sans-serif;

//アイコンフォント
@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot');
  src:  url('../fonts/icomoon.eot') format('embedded-opentype'),
    url('../fonts/icomoon.ttf') format('truetype'),
    url('../fonts/icomoon.woff') format('woff'),
    url('../fonts/icomoon.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}


$icon_mail:"\e900";//mail
$icon_tw: "\ea96"; //twitter
// $icon_tel: "\e904";//tel
// $icon_link: "\e90f"; //別窓表示
// $icon_map:"\e948"; //MAP
// $icon_fb: "\ea90"; //Facebook
// $icon_insta: "\ea92"; //instagram
// $icon_google: "\ea88"; //google
// $icon_youtube: "\ea9d"; //youtube
// $icon_tra_right: "\e903"; //三角矢印右
// $icon_tra_left: "\e902"; //三角矢印左
// $icon_tra_bottom: "\e901"; //三角矢印下
// $icon_tra_top: "\e908"; //三角矢印上*/
